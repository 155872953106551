import { APIUrl, getAPIHeaders } from './utils';
import { OCRApiDocument } from '../types/ocr_document';

export const DocsAPIURL = APIUrl.replace('api', 'ocr.api');

export const healthCheck = async () => {
  const response = await fetch(`${DocsAPIURL}healthcheck`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const getDocumentText = async (documentID: string) => {
  const response = await fetch(`${DocsAPIURL}doc/${documentID}/text`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const getDocumentByHash = async (hash256: string): Promise<OCRApiDocument> => {
  const response = await fetch(`${DocsAPIURL}doc/hash/${hash256}`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const getDocumentMetadata = async (documentID: string): Promise<OCRApiDocument> => {
  const response = await fetch(`${DocsAPIURL}doc/${documentID}`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  if (!response.ok) throw response;
  const result = await response.json();
  return result;
};
