import { DocFetchParams } from './types';
import { OCRApiDocument } from '../../types/ocr_document';
import { getSfSyncDocDataByDocNumber } from '../../interactors/search-api';
import { getDocumentMetadata } from '../../interactors/documents';

export const fetchDocument = async ({ documentNumber, documentId }: DocFetchParams): Promise<OCRApiDocument | void> => {
  if (documentNumber) {
    const documentData = await getSfSyncDocDataByDocNumber(documentNumber);
    documentId = documentData.document.id;
  }
  if (!documentId) throw new Error('Document ID or number must be provided');
  return await getDocumentMetadata(documentId);
};
