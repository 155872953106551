import React, { useState, useContext } from 'react';
import { Box } from '@mui/material';
import { markdownToTxt } from 'markdown-to-txt';
import { IconButton, Tooltip, Popover, TextField, Button } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import { NotificationContext } from '../../App';
import { shareToEmail, getPDFExportLink } from '../../interactors/prompt-sandbox';

interface ShareProps {
  id?: string;
  content: string;
  contentRef: React.RefObject<HTMLDivElement>;
}

export function Share({ contentRef, id, content }: ShareProps) {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);
  const [email, setEmail] = useState('');
  const [emailLoading, setEmailLoading] = useState(false);

  const { setToastMessage } = useContext(NotificationContext);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchor(null);
  };

  const handleChangeEmail = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(ev?.target?.value);
  };

  const handleCopyText = async () => {
    const htmlText = contentRef?.current?.innerHTML;

    const clipboardItemParams: { [key in string]: Blob } = {
      'text/plain': new Blob([markdownToTxt(content)], { type: 'text/plain' }),
    };

    if (htmlText) {
      clipboardItemParams['text/html'] = new Blob([htmlText], { type: 'text/html' });
    }

    const clipboardItem = new ClipboardItem(clipboardItemParams);

    await navigator.clipboard.write([clipboardItem]);
  };

  const handleSendEmail = async () => {
    if (!email || !id) {
      return setToastMessage({
        message: 'Valid email missing',
        severity: 'error',
      });
    }
    setEmailLoading(true);
    try {
      await shareToEmail(email, id);
      setToastMessage({
        message: 'Email sent!',
        severity: 'success',
      });
    } catch (err) {
      setToastMessage({
        message: JSON.stringify(err),
        severity: 'error',
      });
    } finally {
      setEmailLoading(false);
    }
  };

  const handleDownloadPDF = async () => {
    if (!id) return;
    const { link } = await getPDFExportLink(id);
    const anchor = document.createElement('a');
    anchor.download = `Prompt Sandbox - ${new Date().toISOString()}.pdf`;
    anchor.href = link;
    anchor.click();
    anchor.remove();
  };

  return (
    <Box display="flex" marginLeft={'4px'} marginTop={'12px'}>
      <Box>
        <IconButton onClick={handleOpenPopover}>
          <IosShareIcon />
        </IconButton>
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={popoverAnchor}
          open={!!popoverAnchor}
          onClose={handleClosePopover}
        >
          <Box sx={{ p: 2 }}>
            <TextField placeholder="Email" variant="standard" onChange={handleChangeEmail} value={email} />
            <Tooltip title="Email response report" arrow>
              <Button color="info" onClick={handleSendEmail} disabled={emailLoading}>
                <SendIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Copy response to clipboard" arrow>
              <Button color="info" onClick={handleCopyText}>
                <ContentCopyIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Download response report" arrow>
              <Button color="info" onClick={handleDownloadPDF}>
                <PictureAsPdfIcon />
              </Button>
            </Tooltip>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}
