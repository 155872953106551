export const ERROR_MESSAGES = {
  DEFAULT_CHAT_ERROR_RESPONSE: 'Could not generate AI response!',
  INPUT_TOO_LONG: 'The content of your documents and prompts has exceeded the supported limit. Please remove documents or clear chat to continue.',
  CANNOT_SUBMIT_WHILE_DOCUMENT_IS_LOADING: 'Please wait to submit until documents are finished uploading.',
  CANNOT_SUBMIT_WHILE_AI_IS_RESPONDING: 'Please wait to submit until AI is finished generating response.',
  CANNOT_SUBMIT_WITH_NO_DOCS_SUPPLIED: 'Please attach a document before submitting.',
  DOCUMENT_UPLOAD_FAILURE: 'Could not upload the document. Please try again!',
  DOCUMENT_ALREADY_ADDED: 'Document has been already added',
  DOCUMENT_NOT_FOUND: 'Document could not be retrieved',
  INVALID_FILE_TYPE: 'Uploaded file is not a PDF. Please upload a PDF file',
  INVALID_FILE_SIZE: 'This file is too large. Please upload files 20MB or less',
  OCR_TIMEOUT: 'Request timed out during OCR. Please try again',
  INVALID_DOCUMENT_NUMBER: 'Please use a valid document number. (Ex. 1234567-8912)',
};

export const UPLOAD_FILE_ERROR_TO_MESSAGE = {
  "Invalid file type": ERROR_MESSAGES.INVALID_FILE_TYPE,
  "File too big": ERROR_MESSAGES.INVALID_FILE_SIZE,
  "OCR timeout": ERROR_MESSAGES.OCR_TIMEOUT,
};

export const mapPromptApiErrorCodeToMessage = (code?: string): string => {
  switch (code) {
    case 'input_too_long':
      return ERROR_MESSAGES.INPUT_TOO_LONG;
    default:
      return ERROR_MESSAGES.DEFAULT_CHAT_ERROR_RESPONSE;
  }
};
