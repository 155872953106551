import { APIUrl, getAPIHeaders } from './utils';
import AuthStore from '../store/auth';

export const FileAPIURL = APIUrl.replace('api', 'file.api');

export const getFileImage = async (documentID: string) => {
  const response = await fetch(`${FileAPIURL}file/${documentID}/image`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  if (!response.ok) throw response;
  return response.blob;
};

export const getFileOrginal = async (documentID: string) => {
  const response = await fetch(`${FileAPIURL}file/${documentID}/original`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  if (!response.ok) throw response;
  return response.blob();
};

export const getFilePDFOrginal = async (documentID: string) => {
  const response = await fetch(`${FileAPIURL}file/${documentID}/pdf-or-original`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  if (!response.ok) throw response;
  return response.body;
};

export async function* uploadFileSync(file: Blob) {
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(`${FileAPIURL}file/upload`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
    body: formData,
  });

  if (!response.ok) throw response;

  if (!response.body) {
    throw new Error('Readable stream is not supported in this environment.');
  }

  const reader = response.body.getReader();
  let decoderBuffer = '';

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    decoderBuffer += Array.from(value)
      .map((byte) => String.fromCharCode(byte))
      .join('');
    const parts = decoderBuffer.split('\n\n');
    const cleanedParts = parts.map((part) => part.trim().replace('data: ', ''));
    decoderBuffer = parts.pop() || '';

    for (const part of cleanedParts) {
      if (part.trim()) {
        try {
          yield JSON.parse(part.trim());
        } catch (error) {
          console.error('Failed to parse JSON:', error);
        }
      }
    }
  }
}
