import React, { useRef } from 'react';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import { Box } from '@mui/material';
import { FileBox } from '../../../components/DocResolver';
import ReactMarkdown from 'react-markdown';

import { Share } from '../Share';

import './Message.scss';

export function Message({ content, role, id, documentId, deleteDocument }: { content: string; role: string; id?: string; documentId?: string; deleteDocument?: (documentId: string) => void }) {
  const contentRef = useRef<HTMLDivElement>(null);
  const renderMessageBoxContent = () => {
    if (role === 'document') {
      return <FileBox name={content} documentId={documentId} deleteDocument={deleteDocument} />;
    }

    if (role === 'loading_assistant') {
      return (
        <Box className="message-inner-box">
          <Box display="flex" alignItems="center">
            <CircularProgress />
          </Box>
        </Box>
      );
    }

    if (role === 'loading_user') {
      return (
        <Box className="message-inner-box">
          <Box display="flex" alignItems="center">
            <CircularProgress />
            <Box ref={contentRef} marginLeft={'8px'}>
              <ReactMarkdown>{content}</ReactMarkdown>
            </Box>
          </Box>
        </Box>
      );
    }

    if (role === 'error') {
      return (
        <Box className="message-inner-box">
          <Box display="flex" alignItems={'center'} marginRight="8px">
            <ErrorIcon />
          </Box>
          <Box display="flex" alignItems="center">
            <Box ref={contentRef}>
              <ReactMarkdown>{content}</ReactMarkdown>
            </Box>
          </Box>
        </Box>
      );
    }

    if (role === 'streaming') {
      return (
        <Box display="flex" marginBottom={'8px'} maxWidth={'100%'}>
          <Box borderRadius={'5px'} padding={'0 10px'} width={'fit-content'} display="flex" maxWidth="90%">
            <Box display="flex" alignItems="center">
              <Box>
                <ReactMarkdown>{content}</ReactMarkdown>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box className="message-inner-box">
        <Box display="flex" alignItems="center">
          <Box ref={contentRef}>
            <ReactMarkdown>{content}</ReactMarkdown>
          </Box>
        </Box>
        {role === 'assistant' ? <Share contentRef={contentRef} content={content} id={id} /> : null}
      </Box>
    );
  };

  return <Box className={classNames(['message-box', role])}>{renderMessageBoxContent()}</Box>;
}
