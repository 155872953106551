import React from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { PaletteMode } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { getFrontendUrl } from './interactors/utils';
import { getDesignTokens } from './theme';
import AuthStore, { JWTClaimsPlus } from './store/auth';
import PageLayout from './components/PageLayout';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { ToastParams } from './types';
import { PendoProvider } from './provider/Pendo';

export const NotificationContext = createContext({
  setToastMessage: (toastParams: ToastParams) => {},
});

const conf = {
  'mx2.dev': {
    env: 'dev',
    userPoolId: 'us-east-1_owZeNd2Qb',
    userPoolWebClientId: '1bccr6j7f2d1nmuff25r6933e4',
    oauth: {
      domain: 'auth.mx2.dev',
      clientId: '1bccr6j7f2d1nmuff25r6933e4',
    },
    idp: 'mx2lawazure',
    datadog: {
      applicationId: '6aed746b-7728-4dfe-8ca7-e4095ace4205',
      clientToken: 'pub63ae931eca287aa30a968f32be4baa0a',
    },
    pendo: {
      apiKey: '469ecb89-3387-47d7-66d9-706707203089',
    },
  },
  localhost: {
    env: 'local',
    userPoolId: 'us-east-1_owZeNd2Qb',
    userPoolWebClientId: '1bccr6j7f2d1nmuff25r6933e4',
    oauth: {
      domain: 'auth.mx2.dev',
      clientId: '1bccr6j7f2d1nmuff25r6933e4',
    },
    idp: 'mx2lawazure',
    datadog: {
      applicationId: '6aed746b-7728-4dfe-8ca7-e4095ace4205',
      clientToken: 'pub63ae931eca287aa30a968f32be4baa0a',
    },
    pendo: {
      apiKey: '469ecb89-3387-47d7-66d9-706707203089',
    },
  },
  'mx2.law': {
    env: 'prod',
    userPoolId: 'us-east-1_AN7qNv1hR',
    userPoolWebClientId: '17prpvpf9kqi384il1rcvlusqp',
    oauth: {
      domain: 'auth.mx2.law',
      clientId: '17prpvpf9kqi384il1rcvlusqp',
    },
    idp: 'mx2lawazure',
    datadog: {
      applicationId: '6aed746b-7728-4dfe-8ca7-e4095ace4205',
      clientToken: 'pub63ae931eca287aa30a968f32be4baa0a',
    },
    pendo: {
      apiKey: '469ecb89-3387-47d7-66d9-706707203089',
    },
  },
};

export const current_conf = window?.location?.hostname in conf ? conf[window.location.hostname] : conf['mx2.dev'];
export const current_env = window.location.hostname.replace('www.', '').replace('.ws.mx2.dev', '').replace('mx2.law', 'prod').replace('mx2.dev', 'dev');
// const current_conf = conf['mx2.law'];
console.log("current_env",current_env)
datadogRum.init({
  applicationId: current_conf.datadog.applicationId,
  clientToken: current_conf.datadog.clientToken,
  site: 'datadoghq.com',
  service: 'mx2_site-frontend',
  env: current_env,
  version: '1.0.12345689',
  allowedTracingUrls: [/https:\/\/.*\.mx2\.law/, /https:\/\/.*\.mx2\.dev/],
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'allow',
});

datadogLogs.init({
  clientToken: current_conf.datadog.clientToken,
  site: 'datadoghq.com',
  service: 'mx2_site-frontend',
  env: current_conf.env,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: current_conf.userPoolId,
    userPoolWebClientId: current_conf.userPoolWebClientId,
    mandatorySignIn: true,
    oauth: {
      domain: current_conf?.oauth?.domain,
      clientId: current_conf?.oauth?.clientId,
      redirectSignIn: getFrontendUrl(),
      redirectSignOut: getFrontendUrl(),
      responseType: 'code',
    },
  },
  redirectSignIn: getFrontendUrl(),
  redirectSignOut: getFrontendUrl(),
});

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
  forceLightColorMode: () => {},
});

export function ToggleColorMode({ children }: { children: React.ReactNode }) {
  const [mode, setMode] = useState<PaletteMode>((localStorage.getItem('colorMode') || 'light') as PaletteMode);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => {
          const newMode = prevMode === 'dark' ? 'light' : 'dark';
          localStorage.setItem('colorMode', newMode);
          return newMode as PaletteMode;
        });
      },
      forceLightColorMode: () => {
        setMode(() => {
          const mode = localStorage.getItem('colorMode');
          return (mode || 'light') as PaletteMode;
        });
      },
    }),
    []
  );

  const muiTheme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <AuthProvider>{children}</AuthProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

interface AuthContextType {
  store: typeof AuthStore;
  auth: typeof Auth;
  token: string | null;
  user: CognitoUser | null;
  userInfo: JWTClaimsPlus | null;
  onExpire: () => Promise<void>;
  onLogin: () => Promise<void>;
  onLogout: () => void;
}
const blankAuthContext: AuthContextType = {
  store: AuthStore,
  auth: Auth,
  token: null,
  user: null,
  userInfo: null,
  onExpire: async () => {},
  onLogin: async () => {},
  onLogout: () => {},
};

export const AuthContext = React.createContext<AuthContextType>(blankAuthContext);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const colorMode = useContext(ColorModeContext);
  const [firstLoad, setFirstLoad] = useState<Boolean>(false);
  const [userAuth, setUserAuth] = useState<CognitoUser | null>(null);
  const [userInfo, setUserInfo] = useState<JWTClaimsPlus | null>(null);
  const [token, setToken] = useState<string | null>(null);

  if (current_conf.env === 'old') {
    window.location.replace('https://mx2.law');
  }
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'confirmSignUp':
        case 'cognitoHostedUI':
          colorMode.forceLightColorMode();
          return getUser().then(setUser);
          break;
        case 'signOut':
          unSetUser();
          AuthStore.logout();
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then(setUser);
  }, []);

  async function setUser() {
    if (AuthStore.user) {
      setUserAuth(AuthStore.user);
      setToken(AuthStore.accessToken);
      setUserInfo(AuthStore?.userInfo ?? null);
    }
    setFirstLoad(true);
    return AuthStore.user;
  }
  async function unSetUser() {
    setUserAuth(null);
    setToken(null);
  }
  async function getUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return AuthStore.login(user);
    } catch (err) {
      console.warn('Not signed in', err);
    }
    return;
  }

  const handleLogin = async () => {
    Auth.federatedSignIn({ customProvider: current_conf.idp });
  };

  const handleLogout = () => {
    AuthStore.logout();
    setToken(null);
    setUserAuth(null);
  };

  const value = {
    token,
    store: AuthStore,
    auth: Auth,
    user: userAuth,
    userInfo: userInfo,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onExpire: () => Promise.resolve(),
  };

  return (
    <AuthContext.Provider value={{ ...value }}>
      <PendoProvider>{firstLoad && <PageLayout>{children}</PageLayout>}</PendoProvider>
    </AuthContext.Provider>
  );
}
